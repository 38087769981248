/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../pipes/phone-filter.pipe";
import * as i4 from "./status.component";
import * as i5 from "../../services/general-functions.service";
var styles_StatusComponent = [i0.styles];
var RenderType_StatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusComponent, data: {} });
export { RenderType_StatusComponent as RenderType_StatusComponent };
function View_StatusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.cellphone)); _ck(_v, 1, 0, currVal_0); }); }
function View_StatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "ico_success animated shake"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "general-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A1Excelente! "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "general-subtitle text-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" El cobro a la cuenta Nequi "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" sali\u00F3 bien. "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "clear"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "row buttons-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "green-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.functions.goTo("comprobante") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Aceptar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cellphone; _ck(_v, 7, 0, currVal_0); }, null); }
function View_StatusComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.cellphone)); _ck(_v, 1, 0, currVal_0); }); }
function View_StatusComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "ico_error animated shake"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "general-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A1Ups! El pago fall\u00F3 "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "general-subtitle text-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No pudimos hacer el cobro desde la cuenta Nequi "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "clear"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "row buttons-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "green-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.functions.goTo("cobrar") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Aceptar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cellphone; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_StatusComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.PhoneFilterPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "success-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "ico-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.success; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_StatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status", [], null, null, null, View_StatusComponent_0, RenderType_StatusComponent)), i1.ɵdid(1, 114688, null, 0, i4.StatusComponent, [i5.GeneralFunctionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatusComponentNgFactory = i1.ɵccf("app-status", i4.StatusComponent, View_StatusComponent_Host_0, {}, {}, []);
export { StatusComponentNgFactory as StatusComponentNgFactory };
