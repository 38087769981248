import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { UtilsService } from './services/utils.service';
import { GeneralFunctionsService } from './services/general-functions.service';
import { GoogleAnalyticsService } from './services/google-analytics.service'
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Cobros Nequi';
  constructor(private router: Router, private utilsService: UtilsService, private functions: GeneralFunctionsService, private googleAnalyticsService: GoogleAnalyticsService) {
    this.validSession();
    router.events.subscribe(event => {
      window.scrollTo(0, 0);
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.emitPage(event.urlAfterRedirects);
      }
    });
    this.appendGaTrackingCode();
  }

  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '` + environment.googleAnalyticsKey + `', 'auto');
      `;
      document.head.appendChild(script);
    } catch (ex) {
      console.error(ex);
    }
  }
  private validSession() {
    if (!!!this.functions.getCookie('parameters') && !!!this.functions.getCookie('parameters').username) {
      window.location.href = 'https://negocios.nequi.co/login?returnUrl=cobros';
     /*  window.location.href = 'http://localhost:4200/login?returnUrl=cobros'; */
    } else {

    }
  }
}