import { Component, OnInit } from '@angular/core';
import { GeneralFunctionsService } from '../../services/general-functions.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  public success;
  public cellphone;

  constructor(public functions: GeneralFunctionsService) {
  }

  ngOnInit() {
    this.success = (this.functions.getLocalStorage('successPay') == 'true');
    if (!!this.functions.getLocalStorage('cellphone')) {
      this.cellphone = this.functions.getLocalStorage('cellphone');
    }
    if (!!this.functions.getLocalStorage('bill')) {
      var bill:any = this.functions.getLocalStorage('bill') || '';
      this.cellphone = bill.phoneNumber || '';
    }
    if (!!this.functions.getLocalStorage('typePayment')) {
      if (!!this.functions.getLocalStorage('successPay')) {
        this.functions.eventPage('/Cobro exitoso');
        this.functions.eventClick('Cobrar', 'Cobro exitoso', this.functions.getLocalStorage('typePayment'));
      } else {
        this.functions.eventPage('/Cobro fallido');
        this.functions.eventClick('Cobrar', 'Cobro fallido', this.functions.getLocalStorage('typePayment'));
      }
    }else{
      this.functions.goTo('');
    }
  }

}
