<div class="bill-page" *ngIf="bill">
  <div class="bill-box">
      <div class="bill-title">Comprobante del pago</div>
      <div class="underline"></div>
      <div class="text-box-bill">
          <div class="row fields-padding">
              <div class="col-xs-12">
                  <div class="bill-subtitle">Pago</div>
                  <div class="bill-detail">{{bill.value | currency}}</div>
              </div>
              <div class="col-xs-12">
                  <div class="bill-subtitle">Fecha</div>
                  <div class="bill-detail">{{bill.date}}</div>
              </div>
              <div class="col-xs-12">
                  <div class="bill-subtitle">Comercio</div>
                  <div class="bill-detail">{{bill.name}}</div>
              </div>
              <div class="col-xs-12">
                  <div class="bill-subtitle">Código operación</div>
                  <div class="bill-detail">{{bill.trnId}}</div>
              </div>
              <div *ngIf="bill.phoneNumber" class="col-xs-12">
                  <div class="bill-subtitle">Cuenta NEQUI</div>
                  <div class="bill-detail">{{bill.phoneNumber | phoneFilter}}</div>
              </div>
          </div>
      </div>
      <div class="clear"></div>
      <div class="row buttons-padding">
          <div class="col-xs-12">
              <button (click)="finish()" class="green-button">Terminar</button>
          </div>
      </div>
  </div>
</div>