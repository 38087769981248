import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../components/modal/modal.component';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralFunctionsService {
  constructor(private router: Router, private _location: Location, private modalService: NgbModal, private googleAnalyticsService: GoogleAnalyticsService) { }

  goTo(route) {
    this.router.navigate(['/' + route]);
  }
  goBack() {
    this._location.back();
  }
  showModal(type, title?, body?, button?) {
    const modalRef = this.modalService.open(ModalComponent);
    let modalType = 'info';
    switch (type) {
      case 'SessionExpired':
        title = 'zzzzzz…';
        body = 'Llevas mucho tiempo sin moverte, por seguridad cerramos tu sesión.';
        button = 'Listo';
        modalType = 'info';
        break;
      case 'defaultPopupError':
        title = 'Ups, tenemos un problema';
        body = 'Porfa intenta más tarde. Tenemos un problema técnico y no podemos continuar.';
        button = 'Listo';
        modalType = 'error';
        break;
      case 'NoProductAdded':
        title = 'Ups, tenemos un problema';
        body = 'Todavía no tienes el producto Cobros Nequi, pero puedes agregarlo desde tu perfil.';
        button = 'Ir al perfil';
        modalType = 'noC';
    }
    setTimeout(() => {
      modalRef.componentInstance.type = modalType;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.body = body;
      modalRef.componentInstance.button = button;
    }, 0);
  }
  setLocalStorage(key, object) {
    let cadena = JSON.stringify(object);
    let keyBase64 = btoa(key);
    cadena = btoa(cadena);
    localStorage.setItem(keyBase64, cadena);
  }

  getLocalStorage(key) {
    let keyBase64 = btoa(key);
    if (localStorage.getItem(keyBase64)) {
      let object = atob(localStorage.getItem(keyBase64));
      object = JSON.parse(object);
      return object;
    } else {
      return false;
    }
  }

  removeItemLocalStorage(key) {
    let keyBase64 = btoa(key);
    localStorage.removeItem(keyBase64);
  }


  getCookie(cname):any {
    var name = btoa(cname) + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        let request = atob(c.substring(name.length, c.length));
        request = JSON.parse(request);
        return request;
      }
    }
    return "";
  }


  setCookie(cname, cvalue, exHour?, withDomain: boolean = false) {
    let d = new Date();
    let name = btoa(cname);
    let value = JSON.stringify(cvalue);
    value = btoa(value);


    d.setTime(d.getTime() + (exHour * 60 * 1000));
    let expires = "expires=" + d.toUTCString() + ";";
    let domain = '';
    if (withDomain) {
      var parts = location.hostname.split('.'); 
      var subdomain = parts.shift(); 
      var upperleveldomain = parts.join('.'); 
      var sndleveldomain = "";

      if(parts.length > 2){
        sndleveldomain = parts.slice(-3).join('.');
      }else if(parts.length == 2){
        sndleveldomain = parts.slice(-2).join('.'); 
      }
      
      if(sndleveldomain != ""){
        domain =  "domain=." + sndleveldomain + ";";
      }else{
        domain =  "domain=" + subdomain + ";";
      }

    }
    document.cookie = name + "=" + value + ";" + expires + domain +  "path=/";
  }
  logOut() {
    this.setCookie('parameters','',0,true);
    localStorage.clear();
    window.location.href = "https://negocios.nequi.co/login?returnUrl=cobros";
  }
  eventClick(param1, param2, param3) {
    this.googleAnalyticsService.emitEvent(param1, param2, param3);
  }
  eventPage(param1) {
    this.googleAnalyticsService.emitPage(param1);
  }
  getFormattedDate(date = new Date()) {
    date.setDate(date.getDate());
    return date
      .toJSON()
      .substring(0, date.toJSON().indexOf('.'))
      .replace('T', ' ');
  }
}