import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CobrarComponent } from './pages/cobrar/cobrar.component';
import { HistorialComponent } from './pages/historial/historial.component';
import { QrComponent } from './pages/qr/qr.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { StatusComponent } from './pages/status/status.component';
import { ComprobanteComponent } from './pages/comprobante/comprobante.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'cobrar', component: CobrarComponent},
  {path: 'historial', component: HistorialComponent},
  {path: 'qr', component: QrComponent},
  {path: 'verificar', component: VerifyComponent},
  {path: 'status', component: StatusComponent},
  {path: 'comprobante', component: ComprobanteComponent},
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}