/*
 * Copyright 2010-2016 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

var apigClientPartnersFactory = {};
apigClientPartnersFactory.newClient = function (config) {
   var apigClient = { };
    if(config === undefined) {
        config = {
            accessKey: '',
            secretKey: '',
            sessionToken: '',
            region: '',
            apiKey: undefined,
            defaultContentType: 'application/json',
            defaultAcceptType: 'application/json'
        };
    }
    if(config.accessKey === undefined) {
        config.accessKey = '';
    }
    if(config.secretKey === undefined) {
        config.secretKey = '';
    }
    if(config.apiKey === undefined) {
        config.apiKey = '';
    }
    if(config.sessionToken === undefined) {
        config.sessionToken = '';
    }
    if(config.region === undefined) {
        config.region = 'us-east-1';
    }
    //If defaultContentType is not defined then default to application/json
    if(config.defaultContentType === undefined) {
        config.defaultContentType = 'application/json';
    }
    //If defaultAcceptType is not defined then default to application/json
    if(config.defaultAcceptType === undefined) {
        config.defaultAcceptType = 'application/json';
    }

    
    // extract endpoint and path from url
    var invokeUrl = 'https://j8djp409pa.execute-api.us-east-1.amazonaws.com/pdn';
    var endpoint = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl)[1];
    var pathComponent = invokeUrl.substring(endpoint.length);

    var sigV4ClientConfig = {
        accessKey: config.accessKey,
        secretKey: config.secretKey,
        sessionToken: config.sessionToken,
        serviceName: 'execute-api',
        region: config.region,
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var authType = 'NONE';
    if (sigV4ClientConfig.accessKey !== undefined && sigV4ClientConfig.accessKey !== '' && sigV4ClientConfig.secretKey !== undefined && sigV4ClientConfig.secretKey !== '') {
        authType = 'AWS_IAM';
    }

    var simpleHttpClientConfig = {
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var apiGatewayClient = apiGateway.core.apiGatewayClientFactory.newClient(simpleHttpClientConfig, sigV4ClientConfig);
    
    
    
    apigClient.servicesGeneralservicesGetcontractinfobyproductPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesGeneralservicesGetcontractinfobyproductPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-generalservices-getcontractinfobyproduct').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesGeneralservicesGetcontractinfobyproductPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesGeneralservicesGetcontractinfobyproductOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesGeneralservicesGetcontractinfobyproductOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-generalservices-getcontractinfobyproduct').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesGeneralservicesGetcontractinfobyproductOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesGeneralservicesGetparametersPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesGeneralservicesGetparametersPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-generalservices-getparameters').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesGeneralservicesGetparametersPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesGeneralservicesGetparametersOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesGeneralservicesGetparametersOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-generalservices-getparameters').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesGeneralservicesGetparametersOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesLocationservicesGetlocationsbyparentPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesLocationservicesGetlocationsbyparentPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-locationservices-getlocationsbyparent').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesLocationservicesGetlocationsbyparentPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesLocationservicesGetlocationsbyparentOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesLocationservicesGetlocationsbyparentOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-locationservices-getlocationsbyparent').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesLocationservicesGetlocationsbyparentOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesAddproductPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesAddproductPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-addproduct').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesAddproductPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesAddproductOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesAddproductOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-addproduct').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesAddproductOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesCreatepartnerPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesCreatepartnerPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-createpartner').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesCreatepartnerPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesCreatepartnerOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesCreatepartnerOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-createpartner').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesCreatepartnerOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesDeletepartnerPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesDeletepartnerPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-deletepartner').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesDeletepartnerPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesDeletepartnerOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesDeletepartnerOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-deletepartner').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesDeletepartnerOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesGetpartnerinfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesGetpartnerinfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-getpartnerinfo').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesGetpartnerinfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesGetpartnerinfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesGetpartnerinfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-getpartnerinfo').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesGetpartnerinfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesInternalgetpartnerinfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesInternalgetpartnerinfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-internalgetpartnerinfo').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesInternalgetpartnerinfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesInternalgetpartnerinfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesInternalgetpartnerinfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-internalgetpartnerinfo').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesInternalgetpartnerinfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesUpdateuserinfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesUpdateuserinfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-updateuserinfo').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesUpdateuserinfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesPartnerservicesUpdateuserinfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesPartnerservicesUpdateuserinfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-partnerservices-updateuserinfo').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesPartnerservicesUpdateuserinfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesReportsserviceGetreportsPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesReportsserviceGetreportsPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-reportsservice-getreports').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesReportsserviceGetreportsPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesReportsserviceGetreportsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesReportsserviceGetreportsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-reportsservice-getreports').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesReportsserviceGetreportsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesSessionservicesLoginPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesSessionservicesLoginPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-sessionservices-login').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesSessionservicesLoginPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesSessionservicesLoginOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesSessionservicesLoginOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-sessionservices-login').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesSessionservicesLoginOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesSessionservicesLogoutPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesSessionservicesLogoutPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-sessionservices-logout').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesSessionservicesLogoutPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesSessionservicesLogoutOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesSessionservicesLogoutOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-sessionservices-logout').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesSessionservicesLogoutOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesSessionservicesRefreshloginPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesSessionservicesRefreshloginPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-sessionservices-refreshlogin').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesSessionservicesRefreshloginPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesSessionservicesRefreshloginOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesSessionservicesRefreshloginOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-sessionservices-refreshlogin').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesSessionservicesRefreshloginOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesUserservicesChangepasswordPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesUserservicesChangepasswordPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-userservices-changepassword').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesUserservicesChangepasswordPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesUserservicesChangepasswordOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesUserservicesChangepasswordOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-userservices-changepassword').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesUserservicesChangepasswordOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesUserservicesForgotpasswordPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesUserservicesForgotpasswordPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-userservices-forgotpassword').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesUserservicesForgotpasswordPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesUserservicesForgotpasswordOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesUserservicesForgotpasswordOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-userservices-forgotpassword').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesUserservicesForgotpasswordOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesUserservicesResetpasswordPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesUserservicesResetpasswordPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-userservices-resetpassword').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesUserservicesResetpasswordPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesUserservicesResetpasswordOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesUserservicesResetpasswordOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-userservices-resetpassword').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesUserservicesResetpasswordOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesSendotpnequiaccountPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesSendotpnequiaccountPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-sendotpnequiaccount').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesSendotpnequiaccountPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesSendotpnequiaccountOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesSendotpnequiaccountOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-sendotpnequiaccount').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesSendotpnequiaccountOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesSendsmsotpPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesSendsmsotpPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-sendsmsotp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesSendsmsotpPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesSendsmsotpOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesSendsmsotpOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-sendsmsotp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesSendsmsotpOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidatebancolombiaaccountPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidatebancolombiaaccountPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validatebancolombiaaccount').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidatebancolombiaaccountPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidatebancolombiaaccountOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidatebancolombiaaccountOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validatebancolombiaaccount').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidatebancolombiaaccountOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidateemailPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidateemailPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validateemail').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidateemailPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidateemailOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidateemailOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validateemail').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidateemailOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidateotpnequiaccountPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidateotpnequiaccountPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validateotpnequiaccount').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidateotpnequiaccountPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidateotpnequiaccountOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidateotpnequiaccountOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validateotpnequiaccount').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidateotpnequiaccountOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidatepersonalinformationPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidatepersonalinformationPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validatepersonalinformation').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidatepersonalinformationPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidatepersonalinformationOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidatepersonalinformationOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validatepersonalinformation').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidatepersonalinformationOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidatepersoninriskcentralsPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidatepersoninriskcentralsPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validatepersoninriskcentrals').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidatepersoninriskcentralsPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidatepersoninriskcentralsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidatepersoninriskcentralsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validatepersoninriskcentrals').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidatepersoninriskcentralsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidatesmsotpPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidatesmsotpPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validatesmsotp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidatesmsotpPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidatesmsotpOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidatesmsotpOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validatesmsotp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidatesmsotpOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidateunregisteredcommercePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidateunregisteredcommercePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validateunregisteredcommerce').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidateunregisteredcommercePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.servicesValidationservicesValidateunregisteredcommerceOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var servicesValidationservicesValidateunregisteredcommerceOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/-services-validationservices-validateunregisteredcommerce').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(servicesValidationservicesValidateunregisteredcommerceOptionsRequest, authType, additionalParams, config.apiKey);
    };
    

    return apigClient;
};

export var apigClientPartners = apigClientPartnersFactory;