<div class="success-page">
  <div class="ico-box">
      <div *ngIf="success">
          <div class="ico_success animated shake"></div>
          <div class="general-title">
              ¡Excelente!
          </div>
          <div class="general-subtitle text-box">
              El cobro a la cuenta Nequi <span *ngIf="cellphone">{{cellphone | phoneFilter}}</span> salió bien.
          </div>
          <div class="clear"></div>
          <div class="row buttons-padding">
              <div class="col-xs-12">
                  <button (click)="functions.goTo('comprobante')" class="green-button">Aceptar</button>
              </div>
          </div>
      </div>
      <div *ngIf="!success">
          <div class="ico_error animated shake"></div>
          <div class="general-title">
              ¡Ups! El pago falló
          </div>
          <div class="general-subtitle text-box">
              No pudimos hacer el cobro desde la cuenta Nequi <span *ngIf="cellphone">{{cellphone | phoneFilter}}</span>
          </div>
          <div class="clear"></div>
          <div class="row buttons-padding">
              <div class="col-xs-12">
                  <button (click)="functions.goTo('cobrar')" class="green-button">Aceptar</button>
              </div>
          </div>
      </div>
  </div>
</div>