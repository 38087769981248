import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GeneralFunctionsService } from '../../services/general-functions.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showMenu:boolean = false;
  isOpen:boolean = false;
  showBack:boolean = false;


  constructor(private router: Router,public functions:GeneralFunctionsService) {
    this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        if (value.url==='/'){
          this.showMenu = true;
          this.showBack = false;
        }else{
          this.showMenu = false;
          this.showBack = true;
        }
      }
    });
  }

  ngOnInit() {
  }
}
