import { map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { environment } from '../../environments/environment';
import { GeneralFunctionsService } from './general-functions.service';
import * as i0 from "@angular/core";
import * as i1 from "./general-functions.service";
var UtilsService = /** @class */ (function () {
    function UtilsService(functions) {
        var _this = this;
        this.functions = functions;
        this.params = {
            RequestMessage: {
                RequestHeader: {
                    Channel: "",
                    RequestDate: new Date().toJSON(),
                    MessageID: "",
                    ClientID: "",
                    Destination: {}
                },
                RequestBody: {
                    any: {}
                }
            }
        };
        this.eventKey();
        setInterval(function () {
            _this.eventKey();
        }, 3300000);
    }
    UtilsService.prototype.createNewClient = function (config) {
        if (config === void 0) { config = undefined; }
        this.apiClientC = environment.apigClientPayments.newClient(config);
        this.apiClientP = environment.apigClientPartners.newClient(config);
    };
    UtilsService.prototype.getRequest = function (body) {
        var messageID = Date.now().toString();
        messageID = messageID.substring(messageID.length - 9);
        this.params.RequestMessage.RequestHeader.MessageID = messageID;
        this.params.RequestMessage.RequestHeader.Channel = body.Channel;
        this.params.RequestMessage.RequestHeader.ClientID = body.ClientID;
        this.params.RequestMessage.RequestHeader.Destination = body.Destination;
        this.params.RequestMessage.RequestBody.any = body.Body;
        return this.params;
    };
    UtilsService.prototype.consumeEvent = function (event, body, type) {
        var _this = this;
        if (type === void 0) { type = "C"; }
        var apiClient = type == "C" ? this.apiClientC : this.apiClientP;
        var request = this.getRequest(body);
        var observable = from(apiClient[event]('', request, ''))
            .pipe(map(function (res) {
            return _this.validateData(res);
        }), catchError(function (error) {
            return _this.validateData(error);
        }));
        return observable;
    };
    UtilsService.prototype.validateData = function (res) {
        try {
            if (res.status == 200 && res.data.ResponseMessage.ResponseHeader.Status.StatusCode == "0") {
                return res.data.ResponseMessage.ResponseBody.any;
            }
            else {
                if (res.data.ResponseMessage) {
                    if (res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-05A' || res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-07A') {
                        this.functions.showModal('defaultPopupError');
                    }
                }
                else {
                    this.functions.showModal('defaultPopupError');
                }
                return { error: true, res: res };
            }
        }
        catch (error) {
            this.functions.showModal('defaultPopupError');
        }
    };
    UtilsService.prototype.reload = function () {
        location.reload();
    };
    UtilsService.prototype.eventKey = function () {
        var _this = this;
        var dataLocal = this.functions.getCookie('parameters');
        if (!!dataLocal) {
            this.createNewClient({
                accessKey: dataLocal.accessKey,
                secretKey: dataLocal.secretKey,
                sessionToken: dataLocal.sessionToken,
                accessToken: dataLocal.accessToken,
                apiKey: dataLocal.apiKey
            });
            var body = {
                Channel: "NegociosNequi",
                ClientID: dataLocal.username,
                Destination: {
                    "ServiceName": "PartnerService",
                    "ServiceOperation": "refreshLogin",
                    "ServiceRegion": dataLocal.region,
                    "ServiceVersion": "1.0.0"
                },
                Body: {
                    "refreshLoginRQ": {}
                }
            };
            return this.consumeEvent('servicesSessionservicesRefreshloginPost', body, 'P').toPromise().then(function (data) {
                if (!data.error) {
                    _this.functions.setCookie('parameters', data.refreshLoginRS, 480, true);
                    _this.createNewClient({
                        accessKey: data.refreshLoginRS.accessKey,
                        secretKey: data.refreshLoginRS.secretKey,
                        sessionToken: data.refreshLoginRS.sessionToken,
                        accessToken: data.refreshLoginRS.accessToken,
                        apiKey: data.refreshLoginRS.apiKey
                    });
                    if (!!!_this.functions.getLocalStorage('cobrosId') || _this.functions.getLocalStorage('cobrosId') != data.refreshLoginRS.companyId + '_' + data.refreshLoginRS.subsidiaryId) {
                        var body_1 = {
                            Channel: "PN01-C001",
                            ClientID: "3999999999",
                            Destination: {
                                "ServiceName": "PartnerService",
                                "ServiceOperation": "getPartnerInfo",
                                "ServiceRegion": "C001",
                                "ServiceVersion": "1.0.0"
                            },
                            Body: {
                                "getPartnerInfoRQ": {}
                            }
                        };
                        _this.consumeEvent('servicesPartnerservicesGetpartnerinfoPost', body_1, 'P').subscribe(function (data) {
                            if (!data.error) {
                                var canUse = false;
                                data.getPartnerInfoRS.subsidiary.products.forEach(function (element) {
                                    if (element.name == 'COBROS_NEQUI') {
                                        canUse = true;
                                    }
                                });
                                if (canUse) {
                                    _this.functions.setLocalStorage('cobrosId', data.getPartnerInfoRS.company.commerceId + '_' + data.getPartnerInfoRS.company.companyId + '_' + data.getPartnerInfoRS.subsidiary.subsidiaryId);
                                    _this.functions.setLocalStorage('cobrosName', data.getPartnerInfoRS.subsidiary.name);
                                }
                                else {
                                    _this.functions.showModal('NoProductAdded');
                                }
                            }
                            else {
                                _this.functions.showModal('defaultPopupError');
                            }
                        });
                    }
                }
                else {
                    _this.functions.setCookie('parameters', '', 0, true);
                    window.location.href = "https://negocios.nequi.co/login?returnUrl=cobros";
                }
            });
        }
        else {
            this.functions.setCookie('parameters', '', 0, true);
            window.location.href = "https://negocios.nequi.co/login?returnUrl=cobros";
        }
    };
    UtilsService.ngInjectableDef = i0.defineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.inject(i1.GeneralFunctionsService)); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
