<div class="collect-verify-page">
    <div class="ico-box">
        <div class="ico-verify animated swing"></div>
        <div class="general-subtitle">
            Estamos procesando el pago :)
            <br><br>Si está tardando mucho puedes ir a atrás para seguirle cobrando a tus otros clientes.
        </div>
        <div class="clear"></div>
        <div class="row">
            <div class="col-xs-12">
                <div *ngIf="msgError" class="errorBox">
                    {{msgError}}
                </div>
            </div>
        </div>
        <div class="row buttons-padding">
            <div class="col-xs-12">
                <button (click)="functions.eventClick('Cobrar', 'Verificar pago notificacion', '');verifyPayment()" class="green-button" [disabled]="isLoading" *ngIf="showVerifyButton">
                    <span *ngIf="!isLoading">Verificar</span>
                    <span [ngClass]="{'loadingImg':isLoading}"></span>
                </button>
            </div>
            <div class="col-xs-2"></div>
        </div>
    </div>
</div>