import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { CurrencyMaskModule } from "ngx-currency-mask";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ngx-currency-mask/src/currency-mask.config";
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { CobrarComponent } from './pages/cobrar/cobrar.component';
import { HistorialComponent } from './pages/historial/historial.component';
import { QrComponent } from './pages/qr/qr.component';
import { QRCodeModule } from 'angularx-qrcode';
import { VerifyComponent } from './pages/verify/verify.component';
import { StatusComponent } from './pages/status/status.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './components/modal/modal.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PhoneFilterPipe } from './pipes/phone-filter.pipe';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { UtilsService } from './services/utils.service';
import { ComprobanteComponent } from './pages/comprobante/comprobante.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ".",
  precision: 0,
  prefix: "$ ",
  suffix: "",
  thousands: ","
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    CobrarComponent,
    HistorialComponent,
    QrComponent,
    VerifyComponent,
    StatusComponent,
    ModalComponent,
    PhoneFilterPipe,
    ComprobanteComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    CurrencyMaskModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    NgbActiveModal
  ],
  entryComponents: [
    ModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
