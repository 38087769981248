import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { from, throwError, of } from 'rxjs';
import { environment } from '../../environments/environment';
/* import * as AWS from "../../../lib/aws/aws-sdk.min.js" */

import { CognitoUserPool } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import * as awsservice from "aws-sdk/lib/service";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import { GeneralFunctionsService } from './general-functions.service';

export interface data {
  RequestMessage: {
    RequestHeader: {
      Channel: string,
      RequestDate: string,
      MessageID: string,
      ClientID: string,
      Destination: object
    },
    RequestBody: {
      any: object
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private params: data;
  private apiClientC: any;
  private apiClientP: any;

  constructor(private functions: GeneralFunctionsService) {
    this.params = {
      RequestMessage: {
        RequestHeader: {
          Channel: "",
          RequestDate: new Date().toJSON(),
          MessageID: "",
          ClientID: "",
          Destination: {}
        },
        RequestBody: {
          any: {}
        }
      }
    }
    this.eventKey();

    setInterval(() => {
      this.eventKey();
    }, 3300000);
  }

  createNewClient(config = undefined) {
    this.apiClientC = environment.apigClientPayments.newClient(config);
    this.apiClientP = environment.apigClientPartners.newClient(config);
  }

  getRequest(body) {
    let messageID = Date.now().toString();
    messageID = messageID.substring(messageID.length - 9);
    this.params.RequestMessage.RequestHeader.MessageID = messageID;
    this.params.RequestMessage.RequestHeader.Channel = body.Channel;
    this.params.RequestMessage.RequestHeader.ClientID = body.ClientID;
    this.params.RequestMessage.RequestHeader.Destination = body.Destination;
    this.params.RequestMessage.RequestBody.any = body.Body;
    return this.params;
  }

  consumeEvent(event, body, type = "C") {
    var apiClient = type == "C" ? this.apiClientC : this.apiClientP;
    let request = this.getRequest(body);
    let observable = from(apiClient[event]('', request, ''))
      .pipe(
        map((res: any) => {
          return this.validateData(res);
        }),
        catchError((error: any) => {
          return this.validateData(error);
        })
      );

    return observable;
  }

  validateData(res) {
    try {
      if (res.status == 200 && res.data.ResponseMessage.ResponseHeader.Status.StatusCode == "0") {
        return res.data.ResponseMessage.ResponseBody.any
      } else {
        if (res.data.ResponseMessage) {
          if (res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-05A' || res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-07A') {
            this.functions.showModal('defaultPopupError');
          }
        } else {
          this.functions.showModal('defaultPopupError');
        }
        return { error: true, res };
      }
    } catch (error) {
      this.functions.showModal('defaultPopupError');
    }
  }

  reload() {
    location.reload();
  }

  eventKey() {
    let dataLocal: any = this.functions.getCookie('parameters');

    if (!!dataLocal) {
      this.createNewClient({
        accessKey: dataLocal.accessKey,
        secretKey: dataLocal.secretKey,
        sessionToken: dataLocal.sessionToken,
        accessToken: dataLocal.accessToken,
        apiKey: dataLocal.apiKey
      });

      let body = {
        Channel: "NegociosNequi",
        ClientID: dataLocal.username,
        Destination: {
          "ServiceName": "PartnerService",
          "ServiceOperation": "refreshLogin",
          "ServiceRegion": dataLocal.region,
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "refreshLoginRQ": {}
        }
      }
      return this.consumeEvent('servicesSessionservicesRefreshloginPost', body, 'P').toPromise().then((data) => {
        if (!data.error) {
          this.functions.setCookie('parameters', data.refreshLoginRS, 480, true);
          this.createNewClient({
            accessKey: data.refreshLoginRS.accessKey,
            secretKey: data.refreshLoginRS.secretKey,
            sessionToken: data.refreshLoginRS.sessionToken,
            accessToken: data.refreshLoginRS.accessToken,
            apiKey: data.refreshLoginRS.apiKey
          });
          if (!!!this.functions.getLocalStorage('cobrosId') || this.functions.getLocalStorage('cobrosId') != data.refreshLoginRS.companyId + '_' + data.refreshLoginRS.subsidiaryId) {
            let body = {
              Channel: "PN01-C001",
              ClientID: "3999999999",
              Destination: {
                "ServiceName": "PartnerService",
                "ServiceOperation": "getPartnerInfo",
                "ServiceRegion": "C001",
                "ServiceVersion": "1.0.0"
              },
              Body: {
                "getPartnerInfoRQ": {}
              }
            }
            this.consumeEvent('servicesPartnerservicesGetpartnerinfoPost', body, 'P').subscribe((data) => {
              if (!data.error) {
                var canUse = false;
                data.getPartnerInfoRS.subsidiary.products.forEach(element => {
                  if(element.name == 'COBROS_NEQUI'){
                    canUse = true;
                  }
                });
                if (canUse) {
                  this.functions.setLocalStorage('cobrosId', data.getPartnerInfoRS.company.commerceId + '_' + data.getPartnerInfoRS.company.companyId+'_'+data.getPartnerInfoRS.subsidiary.subsidiaryId);
                  this.functions.setLocalStorage('cobrosName', data.getPartnerInfoRS.subsidiary.name);
                } else {
                  this.functions.showModal('NoProductAdded');
                }
              } else {
                this.functions.showModal('defaultPopupError');
              }
            });
          }
        } else {
          this.functions.setCookie('parameters', '', 0, true);
          window.location.href = "https://negocios.nequi.co/login?returnUrl=cobros";
        }
      });
    } else {
      this.functions.setCookie('parameters', '', 0, true);
      window.location.href = "https://negocios.nequi.co/login?returnUrl=cobros";
    }
  }
}
