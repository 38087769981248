<section class="history-container">
    <div class="history-box">
        <div class="history-text-box">
            <div class="row">
                <div class="col-xs-12">
                    <div class="ico-history animated rubberBand"></div>
                </div>
                <div class="col-xs-12">
                    <div *ngIf="functions.getLocalStorage('cobrosName')" class="text-commerce-name">{{functions.getLocalStorage('cobrosName')}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="general-title text-subtitle">Tu reporte de hoy</div>
    <!--second box-->
    <div class="history-second-box">
        <div [ngClass]="{'loadingHistory': isLoading}"></div>
        <div *ngFor="let sales of salesList" class="list-resizable">
            <div class="row payment-box">
                <div class="col-xs-8">
                    <p class="text-history-id">{{sales.tranId}}</p>
                    <p *ngIf="!!sales.phone" class="text-history-phone">{{sales.phone | phoneFilter}}</p>
                    <p class="text-history-date">{{sales.pstdDate}}</p>
                </div>
                <div class="col-xs-4">
                    <p class="floatRight text-history-value">{{sales.tranAmt | currency:'COP':'symbol-narrow':'1.0-0'}}</p>
                </div>
            </div>
            <div class="underline"></div>
        </div>
    </div>
    <div class="viewMore-box buttons-padding">
        <div *ngIf="msgError" class="errorBox">
            {{msgError}}
        </div>
        <div class="clear"></div>
        <div class="row buttons-padding">
            <div class="col-xs-12 col-sm-12" *ngIf="showViewMore">
                <button id="bottom" (click)="viewMore(); viewMoreLoading=true" class="green-button" [disabled]="viewMoreLoading">
                    <span *ngIf="!viewMoreLoading">Ver más </span>
                    <span [ngClass]="{'loadingImg': viewMoreLoading}"></span>
                </button>
            </div>
            <div class="col-xs-12 col-sm-12">
                <a (click)="functions.eventClick('Reportes', 'Reportes anteriores', '')" href="https://negocios.nequi.co/privado/perfil/5" class="transparent-green-button nlink">Tus reportes anteriores</a>
            </div>
        </div>
    </div>
</section>