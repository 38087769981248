<div class="general-header">
    <nav class="top-nav">
        <span (click)="functions.goTo('')" class="ico-nequi"></span>
        <span (click)="functions.goBack()" class="ico-goBack" *ngIf="showBack"></span>
        <div class="btn-menu-hamburguer" *ngIf="showMenu">
            <button class="hamburger hamburger--elastic" [ngClass]="{'is-active': isOpen, 'open-menu': isOpen}" type="button" (click)="isOpen=!isOpen">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
        <div class="mobile-menu" *ngIf="showMenu && isOpen" [ngClass]="{'menu-open': isOpen}">
            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <nav class="menu-navigation">
                        <ul>
                            <a href="https://negocios.nequi.co/privado/perfil">
                                <li>
                                    Mi perfil
                                </li>
                            </a>
                            <a (click)="functions.goTo('cobrar');isOpen=!isOpen">
                                <li>
                                    Cobrar
                                </li>
                            </a>
                            <a (click)="functions.goTo('historial');isOpen=!isOpen">
                                <li>
                                    Historial
                                </li>
                            </a>
                            <a href="https://negocios.nequi.co/privado/perfil/5">
                                <li>
                                    Reportes
                                </li>
                            </a>
                            <a href="https://ayuda.comercios.nequi.co/hc/es-419" target="_blank">
                                <li>
                                    Ayuda
                                </li>
                            </a>
                            <a (click)="functions.logOut()">
                                <li>
                                    Cerrar Sesión
                                </li>
                            </a>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </nav>
</div>