<div class="ico-box">
  <div class="qr-box">  
    <qrcode [qrdata]="qrCode" [size]="250" [level]="'M'" [colordark]="'#231948'"></qrcode>
  </div>
  <div class="clear"></div>
  <div class="general-title">
      Código QR = {{value | currency}}
      <p class="general-subtitle">Tu cliente debe escanear este código
          <br> desde la sección pagar de la app
          <br> Nequi.</p>
  </div>
  <div class="row">
      <div class="col-xs-12">
          <div *ngIf="msgError" class="errorBox">
              {{msgError}}
          </div>
      </div>
  </div>
  <div class="row buttons-padding">
      <div class="col-xs-12 col-sm-12">
          <button (click)="functions.eventClick('Cobrar', 'Verificar pago QR', ''); verifyPayment()" class="green-button" [disabled]="isLoading" *ngIf="showVerifyButton">
              <span *ngIf="!isLoading">Verificar</span>
              <span [ngClass]="{'loadingImg':isLoading}"></span>
          </button>
      </div>
  </div>
</div>