import { Component, Input, OnInit } from '@angular/core';

import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() title;
  @Input() body;
  @Input() button;
  @Input() type=false;

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
  }
  close(type){
    if(type=='noC'){
      window.location.href = "https://negocios.nequi.co/privado/perfil/4";
    }else{
      this.activeModal.close();
    }
  }
}