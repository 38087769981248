import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
/* declare var ga: Function; */

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(public router: Router) {
    /* this.router.events.subscribe(event => {
      try {
        if (typeof ga === 'function') {
          if (event instanceof NavigationEnd) {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
            console.log('%%% Google Analytics page view event %%%');
          }
        }
      } catch (e) {
        console.log(e);
      }
    }); */
  }

  /**
   * Emit google analytics Page
   * Fire event example:
   * this.emitPage("url");
   * @param {string} url
   */
  public emitPage(url: string) {
    (<any>window).ga('set', 'page', url);
    (<any>window).ga('send', 'pageview');
  }

  /**
  * Emit google analytics event
  * Fire event example:
  * this.emitEvent("testCategory", "testAction", "testLabel", 10);
  * @param {string} eventCategory
  * @param {string} eventAction
  * @param {string} eventLabel
  * @param {number} eventValue
  */
  public emitEvent(eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
    (<any>window).ga('send', 'event', { eventCategory, eventAction, eventLabel, eventValue });

  }
}
