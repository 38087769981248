import { Component, OnInit } from '@angular/core';
import { GeneralFunctionsService } from '../../services/general-functions.service';

@Component({
  selector: 'app-comprobante',
  templateUrl: './comprobante.component.html',
  styleUrls: ['./comprobante.component.scss']
})
export class ComprobanteComponent implements OnInit {
  public bill;
  
  constructor(public functions: GeneralFunctionsService) {
    
  }

  ngOnInit() {
    if (!!this.functions.getLocalStorage('bill')) {
      this.bill = this.functions.getLocalStorage('bill');
    }
  }

  finish() {
    this.functions.removeItemLocalStorage('bill');
    this.functions.removeItemLocalStorage('successPay');
    this.functions.removeItemLocalStorage('typePayment');
    this.functions.goTo('');
  }

}
