import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFilter'
})
export class PhoneFilterPipe implements PipeTransform {

  transform(phoneNumber: any): any {
    var text = phoneNumber.toString();
    if (text.length == 10) {
        return "******" + text.substr((text.length - 4), text.length - 1);
    } else if (text.length == 8) {
        return "****" + text.substr((text.length - 4), text.length - 1);
    } else {
        return phoneNumber;
    }
  }

}
