import { Component, OnInit } from '@angular/core';
import { GeneralFunctionsService } from '../../services/general-functions.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {

  /*init variables*/
  public salesList = [];
  public saleDate;
  public isLoading = true;
  public viewMoreLoading = false;
  public showViewMore = false;
  public msgError = '';
  public showFooter = true;
  public info = this.functions.getCookie('parameters');
  public commerceName = this.info.username;

  constructor(public functions: GeneralFunctionsService, public utilsService: UtilsService) {
    this.initView();
  }

  ngOnInit() { }

  initView() {
    let body = {
      Channel: "PN01-C001",
      ClientID: this.info.username,
      Destination: {
        "ServiceName": "DaySalesService",
        "ServiceOperation": "getDaySales",
        "ServiceRegion": this.info.region,
        "ServiceVersion": "1.0.0"
      },
      Body: {
        "daySalesFrontRQ": {
          "code": this.info.companyId + '_' + this.info.subsidiaryId,
          "fromDate": this.functions.getFormattedDate(),
          "numTran": "10",
          "origin": "COBROS_NEQUI"
        }
      }
    }
    this.getSales(body);
  }
  viewMore() {
    let body = {
      Channel: "PN01-C001",
      ClientID: this.info.username,
      Destination: {
        "ServiceName": "DaySalesService",
        "ServiceOperation": "getDaySales",
        "ServiceRegion": this.info.region,
        "ServiceVersion": "1.0.0"
      },
      Body: {
        "daySalesFrontRQ": {
          "fromDate": this.functions.getFormattedDate(this.saleDate),
          "numTran": "10",
          "origin": "COBROS_NEQUI"
        }
      }
    }
    this.getSales(body);
  }
  getSales(body){
    this.utilsService.consumeEvent('cobrosGetdaysalesPost', body).subscribe((data) => {
      if (!data.error) {
        this.isLoading = false;
        data.daySalesFrontRS.transDtls.forEach(element => {
          this.salesList.push(element);
        });
        if(this.salesList.length == 10) {
          this.showViewMore = true;
          var fromDate = this.salesList[this.salesList.length - 1].pstdDate;
          var temp = new Date(fromDate.substring(6, 10).concat('-').concat(fromDate.substring(3, 5)).concat('-').concat(fromDate.substring(0, 2)).concat(fromDate.substring(10, 19))).getTime() - 1000 - (18000000);
          this.saleDate = new Date(temp);
        }else if (this.salesList.length == 0) {
          this.showViewMore = false;
          this.msgError = '¡No tienes ventas reportadas!';
        }else{
          this.showViewMore = false;
        }
      } else {
        this.isLoading = false;
        this.showViewMore = false;
        this.functions.showModal('defaultPopupError');
      }
    });
  }
}
