import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { GeneralFunctionsService } from '../../services/general-functions.service';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-cobrar',
    templateUrl: './cobrar.component.html',
    styleUrls: ['./cobrar.component.scss']
})
export class CobrarComponent implements OnInit {
    public showPhoneNumberField = false;
    public qrIsLoading = false;
    public pushIsLoading = false;
    public isLoading = false;
    public openHelp = false;
    public form;
    public msgError;
    public info = this.functions.getCookie('parameters');

    constructor(formBuilder: FormBuilder, public functions: GeneralFunctionsService, public utilsService: UtilsService) {
        this.form = new FormGroup({
            valor: new FormControl('', [Validators.required, Validators.minLength(0), Validators.maxLength(15), Validators.pattern(/^[1-9]\d*$/)])
        });
    }

    ngOnInit() {
        if (this.functions.getLocalStorage('paymentPending') == 'true') {
            this.functions.showModal('info', 'Tienes un pago pendiente', 'El último cobro que hiciste quedó pendiente. Tu cliente tiene 45 minutos para pagarte, puedes verificar en tu reporte de hoy si te llega la plata o si debes volver a cobrarle.', 'Listo');
            this.functions.removeItemLocalStorage('paymentPending');
        }
    }
    generateQr() {
        this.msgError = '';
        this.qrIsLoading = true;
        this.isLoading = true;
        if (this.form.valid) {
            let value = this.form.value.valor.toString();
            this.functions.setLocalStorage('value', value);
            let body = {
                Channel: "PN01-C001",
                ClientID: this.info.username,
                Destination: {
                    "ServiceName": "PaymentsService",
                    "ServiceOperation": "generateCodeQR",
                    "ServiceRegion": this.info.region,
                    "ServiceVersion": "1.0.0"
                },
                Body: {
                    "generateCodeQRRQ": {
                        "origin": "COBROS_NEQUI",
                        "value": value
                    }
                }
            }
            this.utilsService.consumeEvent('cobrosGeneratecodeqrPost', body).subscribe((data) => {
                if (!data.error) {
                    this.functions.setLocalStorage('codeQr', 'bancadigital-' + data.generateCodeQRRS.codeQR);
                    this.functions.setLocalStorage('validate', data.generateCodeQRRS.codeQR);
                    this.functions.setLocalStorage('paymentPending', 'true');
                    this.functions.goTo('qr');
                } else {
                    this.qrIsLoading = false;
                    this.isLoading = false;
                }
            });
        } else {
            this.msgError = 'Ingresa un valor para poder enviar el push :)';
            this.qrIsLoading = false;
            this.isLoading = false;
        }
    }
    cancelPush() {
    }
    enterPhoneNumber() {
        if (this.form.valid) {
            this.form.addControl('cellphone', new FormControl('', [Validators.required, Validators.pattern(/^(300|301|302|303|304|305|310|311|312|313|314|315|316|317|318|319|320|321|322|323|324|350|399)\d{7}$/)]));
            this.showPhoneNumberField = true;
            this.pushIsLoading = false;
        } else {
            this.msgError = 'Ingresa un valor para poder enviar el push :)';
        }
    }
    pushMethod() {
        this.pushIsLoading = true;
        this.isLoading = true;
        this.msgError = '';
        if (this.form.valid) {
            let value = this.form.value.valor.toString();
            let phoneNumber = this.form.value.cellphone.toString();
            this.functions.setLocalStorage('value', value);
            let body = {
                Channel: "PN01-C001",
                ClientID: this.info.username,
                Destination: {
                    "ServiceName": "PaymentsService",
                    "ServiceOperation": "unregisteredPayment",
                    "ServiceRegion": this.info.region,
                    "ServiceVersion": "1.0.0"
                },
                Body: {
                    "unregisteredPaymentRQ": {
                        "phoneNumber": phoneNumber,
                        "origin": "COBROS_NEQUI",
                        "value": value
                    }
                }
            }
            this.utilsService.consumeEvent('cobrosUnregisteredpaymentPost', body).subscribe((data) => {
                if (!data.error) {
                    this.functions.setLocalStorage('validate', data.unregisteredPaymentRS.transactionId);
                    this.functions.setLocalStorage('paymentPending', 'true');
                    this.functions.goTo('verificar');
                } else {
                    if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-08A' || data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '3-451' || data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '3-455' || data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '11-9L') {
                        this.msgError = 'Revisa el número e inténtalo de nuevo.';
                        this.pushIsLoading = false;
                        this.isLoading = false;
                    }else{
                        this.msgError = 'Ha ocurrido un error inténtalo nuevamente';
                        this.pushIsLoading = false;
                        this.isLoading = false;
                    }
                }
            });
        } else {
            this.msgError = 'Ingresa un número de celular valido :)';
        }
    }
}
