import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../components/modal/modal.component';
import { GoogleAnalyticsService } from './google-analytics.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "./google-analytics.service";
var GeneralFunctionsService = /** @class */ (function () {
    function GeneralFunctionsService(router, _location, modalService, googleAnalyticsService) {
        this.router = router;
        this._location = _location;
        this.modalService = modalService;
        this.googleAnalyticsService = googleAnalyticsService;
    }
    GeneralFunctionsService.prototype.goTo = function (route) {
        this.router.navigate(['/' + route]);
    };
    GeneralFunctionsService.prototype.goBack = function () {
        this._location.back();
    };
    GeneralFunctionsService.prototype.showModal = function (type, title, body, button) {
        var modalRef = this.modalService.open(ModalComponent);
        var modalType = 'info';
        switch (type) {
            case 'SessionExpired':
                title = 'zzzzzz…';
                body = 'Llevas mucho tiempo sin moverte, por seguridad cerramos tu sesión.';
                button = 'Listo';
                modalType = 'info';
                break;
            case 'defaultPopupError':
                title = 'Ups, tenemos un problema';
                body = 'Porfa intenta más tarde. Tenemos un problema técnico y no podemos continuar.';
                button = 'Listo';
                modalType = 'error';
                break;
            case 'NoProductAdded':
                title = 'Ups, tenemos un problema';
                body = 'Todavía no tienes el producto Cobros Nequi, pero puedes agregarlo desde tu perfil.';
                button = 'Ir al perfil';
                modalType = 'noC';
        }
        setTimeout(function () {
            modalRef.componentInstance.type = modalType;
            modalRef.componentInstance.title = title;
            modalRef.componentInstance.body = body;
            modalRef.componentInstance.button = button;
        }, 0);
    };
    GeneralFunctionsService.prototype.setLocalStorage = function (key, object) {
        var cadena = JSON.stringify(object);
        var keyBase64 = btoa(key);
        cadena = btoa(cadena);
        localStorage.setItem(keyBase64, cadena);
    };
    GeneralFunctionsService.prototype.getLocalStorage = function (key) {
        var keyBase64 = btoa(key);
        if (localStorage.getItem(keyBase64)) {
            var object = atob(localStorage.getItem(keyBase64));
            object = JSON.parse(object);
            return object;
        }
        else {
            return false;
        }
    };
    GeneralFunctionsService.prototype.removeItemLocalStorage = function (key) {
        var keyBase64 = btoa(key);
        localStorage.removeItem(keyBase64);
    };
    GeneralFunctionsService.prototype.getCookie = function (cname) {
        var name = btoa(cname) + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                var request = atob(c.substring(name.length, c.length));
                request = JSON.parse(request);
                return request;
            }
        }
        return "";
    };
    GeneralFunctionsService.prototype.setCookie = function (cname, cvalue, exHour, withDomain) {
        if (withDomain === void 0) { withDomain = false; }
        var d = new Date();
        var name = btoa(cname);
        var value = JSON.stringify(cvalue);
        value = btoa(value);
        d.setTime(d.getTime() + (exHour * 60 * 1000));
        var expires = "expires=" + d.toUTCString() + ";";
        var domain = '';
        if (withDomain) {
            var parts = location.hostname.split('.');
            var subdomain = parts.shift();
            var upperleveldomain = parts.join('.');
            var sndleveldomain = "";
            if (parts.length > 2) {
                sndleveldomain = parts.slice(-3).join('.');
            }
            else if (parts.length == 2) {
                sndleveldomain = parts.slice(-2).join('.');
            }
            if (sndleveldomain != "") {
                domain = "domain=." + sndleveldomain + ";";
            }
            else {
                domain = "domain=" + subdomain + ";";
            }
        }
        document.cookie = name + "=" + value + ";" + expires + domain + "path=/";
    };
    GeneralFunctionsService.prototype.logOut = function () {
        this.setCookie('parameters', '', 0, true);
        localStorage.clear();
        window.location.href = "https://negocios.nequi.co/login?returnUrl=cobros";
    };
    GeneralFunctionsService.prototype.eventClick = function (param1, param2, param3) {
        this.googleAnalyticsService.emitEvent(param1, param2, param3);
    };
    GeneralFunctionsService.prototype.eventPage = function (param1) {
        this.googleAnalyticsService.emitPage(param1);
    };
    GeneralFunctionsService.prototype.getFormattedDate = function (date) {
        if (date === void 0) { date = new Date(); }
        date.setDate(date.getDate());
        return date
            .toJSON()
            .substring(0, date.toJSON().indexOf('.'))
            .replace('T', ' ');
    };
    GeneralFunctionsService.ngInjectableDef = i0.defineInjectable({ factory: function GeneralFunctionsService_Factory() { return new GeneralFunctionsService(i0.inject(i1.Router), i0.inject(i2.Location), i0.inject(i3.NgbModal), i0.inject(i4.GoogleAnalyticsService)); }, token: GeneralFunctionsService, providedIn: "root" });
    return GeneralFunctionsService;
}());
export { GeneralFunctionsService };
