import { Component, OnInit } from '@angular/core';
import { GeneralFunctionsService } from '../../services/general-functions.service';
import { CurrencyPipe } from '@angular/common';
import { UtilsService } from '../../services/utils.service';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QrComponent implements OnInit {
  public qrCode = this.functions.getLocalStorage('codeQr');
  public value = this.functions.getLocalStorage('value');
  public msgError = "";
  public isLoading = false;
  public showVerifyButton = true;
  private expiredCodes = ['11-9L', '3-455', '3-451', '10-454'];
  private attempts = 0;
  private max_attempts = 90;
  private time = 30000;
  public intervalFunction;
  public consumeEvent;
  public info = this.functions.getCookie('parameters');

  constructor(public functions: GeneralFunctionsService, public utilsService: UtilsService) {
    if (!!!this.functions.getLocalStorage('validate')) {
      functions.goTo('/cobrar');
    }
  }

  ngOnInit() {
    this.functions.removeItemLocalStorage('successPay');
    let thisF = this;
    this.intervalFunction = setInterval(function () {
      thisF.attempts += 1;
      thisF.sondaVerify();
    }, this.time);
  }

  verifyPayment() {
    let body = {
      Channel: "PN01-C001",
      ClientID: this.info.username,
      Destination: {
        "ServiceName": "PaymentsService",
        "ServiceOperation": "getStatusPayment",
        "ServiceRegion": this.info.region,
        "ServiceVersion": "1.0.0"
      },
      Body: {
        "getStatusPaymentRQ": {
          "codeQR": this.functions.getLocalStorage('validate')
        }
      }
    }
    this.utilsService.consumeEvent('cobrosGetstatuspaymentPost', body).subscribe((data) => {
      if (!data.error) {
        if (data.getStatusPaymentRS.status === '35') {
          this.msgError = '';
          this.functions.setLocalStorage('successPay', 'true');
          this.functions.setLocalStorage('typePayment', 'QR');
          this.functions.removeItemLocalStorage('paymentPending');
          this.functions.setLocalStorage('bill', data.getStatusPaymentRS);
          clearInterval(this.intervalFunction);
          this.functions.goTo('/status');
        } else if (data.getStatusPaymentRS.status === '33') {
          if (this.attempts >= this.max_attempts) {
            clearInterval(this.intervalFunction);
            this.msgError = 'Lo sentimos, el pago ya ha expirado.';
            this.functions.removeItemLocalStorage('paymentPending');
            this.functions.removeItemLocalStorage('validate');
            this.functions.setLocalStorage('successPay', 'false');
            this.functions.setLocalStorage('typePayment', 'Expired');
            this.showVerifyButton = false;
            this.functions.goTo('/status');
          } else {
            this.functions.setLocalStorage('paymentPending', 'true');
            this.msgError = 'Aún no se ha realizado tu pago =(';
          }
        } else if(this.expiredCodes.indexOf(data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode) != -1){
          this.functions.removeItemLocalStorage('paymentPending');
          this.functions.removeItemLocalStorage('validate');
          this.functions.setLocalStorage('successPay', 'false');
          this.functions.setLocalStorage('typePayment', 'Expired');
          this.functions.showModal('error','La transacción ha fallado','No se pudo finalizar correctamente el pago','Listo');
        }
      } else {
        this.functions.removeItemLocalStorage('paymentPending');
        this.functions.removeItemLocalStorage('validate');
        this.functions.setLocalStorage('successPay', 'false');
        this.functions.setLocalStorage('typePayment', 'Error');
        clearInterval(this.intervalFunction);
        this.functions.goTo('/status');
      }
    });
  }

  sondaVerify() {
    if (!!!this.functions.getLocalStorage('validate')) {
      if (!!this.intervalFunction) {
        clearInterval(this.intervalFunction);
      }
      return;
    }
    this.verifyPayment();
  }
  cancel() {
    this.functions.goTo('cobrar');
  }
}
