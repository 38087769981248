<div class="collect-info-page" *ngIf="form">
    <div class="box-collect-info">
        <form [formGroup]="form">
            <div class="ico-collect-info animated zoomIn" [ngClass]="{'ico-enter-phone-number':showPhoneNumberField}"></div>
            <div class="title-collect-info" *ngIf="!showPhoneNumberField">Datos del cobro</div>
            <div class="title-collect-info title-enter-phone-collect-info" *ngIf="showPhoneNumberField">A quién le vas a cobrar</div>
            <div class="text-box-collect-info" *ngIf="!showPhoneNumberField">
                <p>Escribe el valor de la compra y elige una forma de pago</p>
            </div>
            <div class="row">
                <div class="col-xs-12 inputMobile" *ngIf="!showPhoneNumberField">
                    <input 
                        formControlName="valor"
                        type="text"
                        class="inputText"
                        [ngClass]="{'inputError': form.controls['valor'].invalid && form.controls['valor'].touched}"
                        error-field message="msgErrorValue"
                        currencyMask
                    >
                </div>
                <div class="col-xs-12 inputMobile" *ngIf="showPhoneNumberField">
                    <input 
                        type="number" 
                        formControlName="cellphone"
                        type="number"
                        class="inputText"
                        placeholder="Número de celular"
                        [ngClass]="{'inputError': form.controls['cellphone'].invalid && form.controls['cellphone'].touched}"
                    >
                </div>
            </div>
            <div class="clear"></div>
            <div *ngIf="msgError" class="errorBox">
                {{msgError}}
            </div>
            <div class="row buttons-padding buttons-padding-box" *ngIf="!showPhoneNumberField">
                <div class="col-xs-12 col-sm-6">
                    <button (click)="functions.eventClick('Cobrar', 'Cobrar con QR', ''); generateQr()" class="green-button" [disabled]="form.invalid || qrIsLoading">
                        <span *ngIf="!qrIsLoading">Código QR</span>
                        <span [ngClass]="{'loadingImg': qrIsLoading == true}"></span>
                    </button>
                </div>
                <div class="col-xs-12 col-sm-6">
                    <button (click)="functions.eventClick('Cobrar', 'Cobrar con notificacion', ''); enterPhoneNumber()" [disabled]="form.invalid || isLoading" class="green-button">Notificación</button>
                </div>
            </div>
            <div class="row buttons-padding buttons-padding-box" *ngIf="showPhoneNumberField">
                <div class="col-xs-12 col-sm-12">
                    <button (click)="functions.eventClick('Cobrar', 'Enviar notificacion', ''); pushMethod()" class="green-button" [disabled]="form.invalid || pushIsLoading">
                        <span *ngIf="!pushIsLoading">Enviar notificación</span>
                        <span [ngClass]="{'loadingImg': pushIsLoading}"></span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="help-box-container">
    <div class="ico-bar-help-box-collect-info" *ngIf="!showPhoneNumberField" (click)="openHelp=!this.openHelp" [ngClass]="{'open-help-button': openHelp}"
        ngSwipeRight="openHelp = false;" ngSwipeLeft="openHelp = true;">
        <div class="ico-letter-bar-help"></div>
    </div>
    <div class="help-box-collect-info" *ngIf="!showPhoneNumberField" [ngClass]="{'open-help-box': openHelp}" ngSwipeRight="openHelp = false;"
        ngSwipeLeft="openHelp = true;">
        <div class="row elements-help-box-collect-info">
            <div class="col-xs-12 col-sm-12">
                <div class="row">
                    <div class="col-xs-12 col-sm-12">
                        <div class="ico-push-help-box-collect-info"></div>
                    </div>
                    <div class="col-xs-12 col-sm-12">
                        <div class="title-help-box-collect-info">¿Que es un pago con notificación?</div>
                        <div class="text-help-box-collect-info">En este tipo de pago le envías una alerta al celular a tu cliente y él solo debe aprobarlo, no importa si está en tu tienda o en otra ciudad.</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12">
                <div class="row">
                    <div class="col-xs-12 col-sm-12">
                        <div class="ico-qr-help-box-collect-info"></div>
                    </div>
                    <div class="col-xs-12 col-sm-12">
                        <div class="title-help-box-collect-info">¿Que es un pago con código QR?</div>
                        <div class="text-help-box-collect-info">Generas un código en tu dispositivo y tu cliente lo escanea desde la opción pagar en Nequi. Solo
                            funciona para pagos presenciales.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>