<div class="home-page">
    <div class="box-home">
        <div class="ico-home animated tada"></div>
        <div class="home-title">¡Hola!</div>
        <div class="home-text">
            <p>¿Qué quieres hacer?</p>
        </div>
        <div class="row buttons-padding">
            <div class="col-xs-12 col-sm-6 hideMobile">
                <button (click)="functions.eventClick('Home', 'Ver historial', ''); functions.goTo('historial')" class="transparent-green-button">Ver pagos de hoy
                </button>
            </div>
            <div class="col-xs-12 col-sm-6 ">
                <button (click)="functions.eventClick('Home', 'Cobrar', ''); functions.goTo('cobrar')" ng-disabled="isLoading" class="green-button button-login">Cobrar
                </button>
            </div>
            <div class="col-xs-12 col-sm-6 showMobile">
                <button (click)="functions.eventClick('Home', 'Ver historial', ''); functions.goTo('historial')" class="transparent-green-button">Ver pagos de hoy
                </button>
            </div>
        </div>
    </div>
</div>