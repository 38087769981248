import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
/* declare var ga: Function; */
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService(router) {
        this.router = router;
        /* this.router.events.subscribe(event => {
          try {
            if (typeof ga === 'function') {
              if (event instanceof NavigationEnd) {
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');
                console.log('%%% Google Analytics page view event %%%');
              }
            }
          } catch (e) {
            console.log(e);
          }
        }); */
    }
    /**
     * Emit google analytics Page
     * Fire event example:
     * this.emitPage("url");
     * @param {string} url
     */
    GoogleAnalyticsService.prototype.emitPage = function (url) {
        window.ga('set', 'page', url);
        window.ga('send', 'pageview');
    };
    /**
    * Emit google analytics event
    * Fire event example:
    * this.emitEvent("testCategory", "testAction", "testLabel", 10);
    * @param {string} eventCategory
    * @param {string} eventAction
    * @param {string} eventLabel
    * @param {number} eventValue
    */
    GoogleAnalyticsService.prototype.emitEvent = function (eventCategory, eventAction, eventLabel, eventValue) {
        if (eventLabel === void 0) { eventLabel = null; }
        if (eventValue === void 0) { eventValue = null; }
        window.ga('send', 'event', { eventCategory: eventCategory, eventAction: eventAction, eventLabel: eventLabel, eventValue: eventValue });
    };
    GoogleAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(i0.inject(i1.Router)); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}());
export { GoogleAnalyticsService };
